var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('metatag',{attrs:{"title":"'All Users"}}),_c('page-header',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"primary","exact":"","to":{ name: 'users.create' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-plus-outline")]),_c('span',{domProps:{"textContent":_vm._s('Add User')}})],1)]},proxy:true}])}),_c('v-card',[_c('toolbar-menu'),_c('v-slide-y-reverse-transition',{attrs:{"mode":"out-in"}},[(_vm.resourcesIsNotEmpty)?[_c('v-data-table',{attrs:{"headers":_vm.resources.headers,"items":_vm.resources.data,"loading":_vm.resources.loading,"color":"primary","item-key":"id"},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('span')]},proxy:true},{key:"loading",fn:function(){return [_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('skeleton-avatar-table')],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('v-avatar',{staticClass:"mr-6",attrs:{"size":"32","color":"workspace"}},[_c('v-img',{attrs:{"src":item.avatar}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"mt-1"},on),[_c('router-link',{staticClass:"text-no-wrap t-d-hover-lined t-d-none",attrs:{"exact":"","to":{ name: 'users.edit', params: { id: '1' } }},domProps:{"textContent":_vm._s(item.name)}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Edit this user')}})])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{ name: 'users.show', params: { id: '1' } },"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('View details')}})])],1)]}}],null,false,1645653014)})]:[_c('empty-state',{staticClass:"mb-10",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"large":"","color":"primary","exact":"","to":{ name: 'users.create' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-plus-outline")]),_c('span',{domProps:{"textContent":_vm._s('Add user')}})],1)]},proxy:true}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }